import React, { CSSProperties } from "react";
import { Link } from "gatsby";
import designTokens from "../../design-tokens/design-tokens.json";
import { Code } from "../docs/docs-code";
import { HeaderSection, ContentSection } from "../docs/sections";
import { TextContainer, Ul } from "../../lib/src";
import { Header } from "../docs";
import * as scss from "./design-tokens.scss";

function CopyButton({ value }: { value: string }) {
	// TODO: Replace with IconOnlyButton
	return (
		<button onClick={() => navigator.clipboard.writeText(value)} style={{ marginRight: 8 }}>
			Copy
		</button>
	);
}

type ValueProps = {
	category: Categories;
	type: TokenTypes;
	value: string;
	displayValue: string;
};

function Value({ category, type, value, displayValue }: ValueProps) {
	if (category === "Colors" && value.includes("{")) {
		const keys = value.slice(1, -1).split(".").slice(0, -1);

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		let valueObj: Record<string, any> = designTokens;

		for (const property of keys) {
			valueObj = valueObj[property];
		}
		value = valueObj.value;

		return (
			<div className={scss.flexCenter}>
				<span style={{ backgroundColor: value }} className={scss.colorSquare} />
				<Code>
					<Link to={`#${displayValue}`}>{`${displayValue} (${value})`}</Link>
				</Code>
			</div>
		);
	}

	if (category === "Colors") {
		return (
			<div className={scss.flexCenter}>
				<span style={{ backgroundColor: value }} className={scss.colorSquare} />
				<Code>{displayValue}</Code>
			</div>
		);
	}
	if (category === "Typography") {
		switch (type) {
			case "Font-family":
				return <span style={{ fontFamily: value }}>{displayValue}</span>;
			case "Font-size":
				return <span style={{ fontSize: value }}>{displayValue}</span>;
			case "Font-weight":
				return (
					<span style={{ fontWeight: value as CSSProperties["fontWeight"] }}>{displayValue}</span>
				);
		}
	}

	return <div>{displayValue}</div>;
}

type Token = {
	value: string;
	attributes: {
		category: string;
		type?: string;
	};
};

type Format = "scss" | "css" | "js";

type Categories = "Colors" | "Typography" | "Space"; //There are more... but not needed to type for now
type TokenTypes = "Font-family" | "Font-size" | "Font-weight" | "Line-height" | "Neutral"; //There are more... but not needed to type for now

const capitalize = (str: string) => str[0].toUpperCase() + str.slice(1);
function getFormattedName(section: string, name: string, format: Format) {
	switch (format) {
		case "scss":
			return `$${section}--${name}`;
		case "css":
			return `var(--${section}--${name})`;
		case "js":
			return [...capitalize(section).split(/--?/g), ...name.split(/--?/g)].map(capitalize).join("");
	}
}
function getDisplayValue(value: string | number, format: Format) {
	if (typeof value === "number") {
		return value.toString();
	}
	const refRegex = /{(.*?)\.(.*?)\.value}/g;
	if (refRegex.exec(value)) {
		// match means it is a reference to another token,
		// so replace all references with the actual value
		return value.replace(refRegex, (_, section, name) => getFormattedName(section, name, format));
	}
	const remMatch = /([\d\.]+)rem$/g.exec(value);
	if (remMatch) {
		const px = parseFloat(remMatch[1]) * 16;
		return `${value} (${px}px)`;
	}
	return value;
}

function getOrUpdateRecord<TKey extends string, TVal>(
	record: Record<TKey, TVal>,
	key: TKey,
	value: TVal
) {
	const ret = record[key];
	if (ret === undefined) {
		record[key] = value;
		return value;
	}
	return ret;
}

type TokenValue = { sectionName: string; name: string; value: string | number };
const tokensRecord: Record<string, Record<string, TokenValue[]>> = {};
const NO_TYPE = "___NO-TYPE";
for (const [sectionName, section] of Object.entries(designTokens)) {
	for (const [name, token] of Object.entries(section)) {
		const typeRecord = getOrUpdateRecord(tokensRecord, token.attributes.category, {});
		const values = getOrUpdateRecord(typeRecord, (token as Token).attributes.type ?? NO_TYPE, []);
		values.push({ sectionName, name, value: token.value });
	}
}

export default (): JSX.Element => {
	const [format, setFormat] = React.useState<Format>("scss");
	const importStatements = {
		scss: `@import "~@siteimprove/fancylib/design-tokens/variables.scss";`,
		css: `import "@siteimprove/fancylib/design-tokens/variables.css;"`,
		js: `import { ColorWhite /*tokens you want to use*/ } from "@siteimprove/fancylib/design-tokens/variables";`,
	};

	// TODO: Replace buttons with ContentSwitcher
	return (
		<>
			<HeaderSection
				title="Design Tokens"
				subTitle="Tokens for your designs"
				headings={Object.keys(tokensRecord).map((category) => ({ id: category, text: category }))}
			/>
			<ContentSection>
				<TextContainer article>
					<Header.H2>Import design tokens</Header.H2>
					<Header.H3>Choose format</Header.H3>
					<Ul
						items={[
							/* eslint-disable react/jsx-key */
							<button onClick={() => setFormat("scss")}>SCSS</button>,
							<button onClick={() => setFormat("css")}>CSS</button>,
							<button onClick={() => setFormat("js")}>JS</button>,
							/* eslint-enable react/jsx-key */
						]}
					/>

					<Header.H3>Copy import statement</Header.H3>
					<CopyButton value={importStatements[format]} />
					<Code>{importStatements[format]}</Code>

					{Object.entries(tokensRecord).map(([category, types]) => (
						<section className={scss.section} key={category}>
							<TextContainer>
								<Header.H2 id={category}>{category}</Header.H2>
								{Object.entries(types).map(([typeName, values]) => (
									<React.Fragment key={typeName}>
										{typeName !== NO_TYPE && <Header.H3>{typeName}</Header.H3>}
										<table cellSpacing="0" cellPadding="0" className={scss.table}>
											<tbody>
												{values.map(({ sectionName, name, value }) => {
													const variableName = getFormattedName(sectionName, name, format);
													return (
														<tr className={scss.row} key={name}>
															<td style={{ width: "26rem" }}>
																<CopyButton value={variableName} />
																<Code id={variableName}>{variableName}</Code>
															</td>
															<td>
																<Value
																	category={category as Categories}
																	type={typeName as TokenTypes}
																	value={
																		/* this isn't true, but `Value` does the right thing based on category */
																		value as string
																	}
																	displayValue={getDisplayValue(value, format)}
																/>
															</td>
														</tr>
													);
												})}
											</tbody>
										</table>
									</React.Fragment>
								))}
							</TextContainer>
						</section>
					))}
				</TextContainer>
			</ContentSection>
		</>
	);
};
